import React from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/structure/layout"
import SEO from "../components/structure/seo"
import { formatDate } from "../components/helpers"
import { useGetGenericFeaturedImage } from "../hooks/useGetGeneridFeaturedImage"
import Img from "gatsby-image"

import styled from "styled-components"

import { Container } from "../components/styled/container"
import { Colors, Sizes } from "../components/theme"

export default ({ data }) => {
  const post = data.markdownRemark
  const intl = useIntl()
  const locale = intl.locale !== "es" ? `${intl.locale}` : "es"

  if (post === null) {
    return (
      <Layout>
        <Container>
          <h3>No hay contenido para esta página</h3>
        </Container>
      </Layout>
    )
  }

  const featuredImage =
    post.frontmatter.featuredImage !== null
      ? post.frontmatter.featuredImage.childImageSharp.fluid
      : useGetGenericFeaturedImage().childImageSharp.fluid

  const PostHeader = styled.header`
    background: ${Colors.scdsDark};
    padding: 45px 0;
    color: white;

    h1 {
      font-weight: bolder;
    }

    h2 {
      font-weight: normal;
    }

    @media (max-width: ${Sizes.mobile}) {
      padding: 10px 0;
    }
  `

  const PostHeaderInfo = styled.div`
    display: flex;

    div {
      &:nth-child(1) {
        min-width: 300px;
        margin-right: 40px;
      }

      &:nth-child(2) {
        width: 60%;
      }
    }

    @media (max-width: ${Sizes.mobile}) {
      flex-direction: column;
      text-align: center;
      align-items: center;
      div {
        &:nth-child(1) {
          margin: 0;
        }
      }
    }
  `
  const PostContent = styled.div`
    font-family: "Merriweather";
    font-size: 17px;
    line-height: 35px;
    max-width: 700px;
    margin: auto;
  `

  const TimeToRead = styled.span`
    color: #b7b7b7;
    text-transform: uppercase;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
  `
  return (
    <Layout>
      <SEO
        title={`Servicio Climático de Datos | ${post.frontmatter.title}`}
        postDescription={post.frontmatter.description}
      />
      <section>
        <article>
          <PostHeader>
            <Container>
              <PostHeaderInfo>
                <div>
                  <Img fluid={featuredImage} />
                </div>
                <div>
                  <h1>{post.frontmatter.title}</h1>
                  <h2>{post.frontmatter.subtitle}</h2>
                  <div>
                    <time>
                      Escrito el {formatDate(post.frontmatter.date, "Readable")}{" "}
                    </time>
                  </div>
                </div>
              </PostHeaderInfo>
            </Container>
          </PostHeader>
          <Container>
            <PostContent>
              <TimeToRead>
                {locale === "es" ? (
                  post.timeToRead <= 1 ? (
                    <>Tardas {post.timeToRead} minuto en leerlo</>
                  ) : (
                    <>Tardas unos {post.timeToRead} minutos en leerlo</>
                  )
                ) : post.timeToRead <= 1 ? (
                  <>{post.timeToRead} minute read</>
                ) : (
                  <>{post.timeToRead} minutes read</>
                )}
              </TimeToRead>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </PostContent>
          </Container>
        </article>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { language: { eq: $language } }
    ) {
      html
      excerpt
      timeToRead
      frontmatter {
        title
        subtitle
        date
        description
        category
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
